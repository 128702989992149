<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header/>
      <vue-snotify></vue-snotify>
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
          <!-- <div class="content-wrapper"> -->
            <router-view></router-view>
          <!-- </div> content wrapper ends -->
          <hr v-view="onceHandler">
          <Footer class="computer" v-if="displayfooter" />
          <Footer class="mobile"/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
  import Header from "./partials/Header";
  // import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  export default {
    name: "layout",
    components: {
      Header,
      // Sidebar,
      Footer
    },
    data () {
        return {
            displayfooter: false, 
        }
    },
    methods:{
      onceHandler(e){
        if(e.percentTop < 1){
          this.displayfooter = true
        }else{
          this.displayfooter = false
        }
      },
      loadService(){
        //getCart
        if (localStorage.getItem('services')){
        this.$store.commit('SET_SERVICES')
        }
        else{
          // localStorage.setItem('services', [])
          this.$store.services =[]
        }
      },
      loadProduct(){
        if (localStorage.getItem('product')){
        this.$store.commit('SET_PRODUCT')
        }
        else{
          // return
          this.$store.products =[]
        }
      }
    },
    created (){
      this.loadService()
      this.loadProduct()        
    },
    mounted() {
    console.log("router",this.$router.go);
    }
  }
</script>