const cartproduct = {
    state: {
        products:[]
    },
    getters: {
        cartproductLength(state) {
            return state.cartproduct= state.products.length
        }
    },
    mutations: {
        // récupération des products stockés
        SET_PRODUCT(state) {
            state.products =JSON.parse(localStorage.getItem('product'))
        },
        ADD_PRODUCT(state, product){
            // state.cart++;
            state.products.push(product)
            product= 0
            const stringifyproduct = JSON.stringify(state.products)
            localStorage.setItem('product', stringifyproduct)
        },
        REMOVE_PRODUCT_TO_CART (state, index) {
        state.products.splice(index, 1);
        // state.cart --
        const stringifyproduct= JSON.stringify(state.products)
        localStorage.setItem('product', stringifyproduct)
        }
    },
    actions: {
        //add product in cart
        add_product(context,product) {
            context.commit('ADD_PRODUCT',product)
        },
        removeProductToCart(context, index) {
            context.commit('REMOVE_PRODUCT_TO_CART', index)
        },
    }
}

export default cartproduct;