import axios from "axios"
const cart = {
    state: {
        services:[],
        scheduled:[],
        closing:[]
    },
    getters: {
        cartLength(state) {
            return state.cart= state.services.length
        },
        totalDuration(state){
            let sum = 0
            for (let service of state.services){
                sum = sum + service.duration
            }
            return sum
        }
    },
    mutations: {
        // récupération des services stockés
        SET_SERVICES(state) {
            state.services =JSON.parse(localStorage.getItem('services'))
        },
        ADD_SERVICE(state, service){
            // state.cart++;
            state.services.push(service)
            service= 0
            const stringifyService = JSON.stringify(state.services)
            localStorage.setItem('services', stringifyService)
        },
        REMOVE_SERVICE_TO_CART (state, index) {
        state.services.splice(index, 1);
        // state.cart --
        const stringifyService= JSON.stringify(state.services)
        localStorage.setItem('services', stringifyService)
        },
        GET_SCHEDULED(state, scheduled){
            // state.scheduled = scheduled
            let data = []
            if(state.scheduled.length==0){
                data=scheduled
                console.log("if",data);
            }else{
                data=[...state.scheduled, ...scheduled]
                console.log("else",data);
            }
            state.scheduled = data
        },
        LIST_CLOSING(state, closing){
            state.closing = closing
        }
    },
    actions: {
        //add services in cart
        add_service(context,service) {
            context.commit('ADD_SERVICE',service)
        },
        removeServiceToCart(context, index) {
            context.commit('REMOVE_SERVICE_TO_CART', index)
        },
        //get the scheduled reservation
        get_scheduled(context){
            // context.dispatch("list_closing")
            context.dispatch("list_closing_repeat")
            axios.get('schedules/appointments')
            .then(
                respurchases => {
                    console.log("date scheduled", respurchases);
                    context.commit('GET_SCHEDULED',respurchases.data)
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )
        },
        //get closing avec répétition
        list_closing_repeat(context){
            axios.get('closing/schedules')
            .then(
                resclosing => {
                    context.commit('GET_SCHEDULED',resclosing.data)
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )
        },
        // get closing sans répétition
        list_closing(context){
            console.log("déclencé list_closing");
            axios.get(`closing/dates`)
            .then(res=>{
                let events = []
                let closingdates = res.data.result
                for (let date of closingdates) {
                    // si la fermeture n'est pas une répétition
                    if(!date.repeating){
                        events.push({
                         datestart:date.start_closing_at,
                         dateend:date.end_closing_at
                        })
                    }
                }
                context.commit('LIST_CLOSING',events)
            })
            .catch(errGetOrder=>console.log(errGetOrder))
        },
    }
}

export default cart;