/*
*On stock les données centralisée avec Vuex
*afin de fournir des fonctionnalités avancées
*/
import Vue from'vue'
import Vuex from 'vuex'
import authentification from "./modules/authentification";
import cart from './modules/cart'
import cartproduct from './modules/cartproduct'


Vue.use(Vuex)

const store = () => {
    return new Vuex.Store({
      modules: {
          cart:cart,
          cartproduct:cartproduct,
          authentification:authentification
      }
    });
  };

  export default store;