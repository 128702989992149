import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import router from './router/maintenance'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'
import VueFilterDateFormat from 'vue-filter-date-format';
import checkView from 'vue-check-view'
import Vuelidate from 'vuelidate'

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css';
import axios from 'axios'

import Snotify, { SnotifyPosition} from 'vue-snotify'
  const options = {
    toast: {
      position: SnotifyPosition.rightTop,
    }
  }
Vue.use(Snotify, options)
axios.defaults.baseURL = process.env.VUE_APP_URL;
// axios.defaults.baseURL = "https://api-meb.ailem.io";

axios.defaults.withCredentials = true
AOS.init()

Vue.use(ProductZoomer)
Vue.use(checkView)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Vuelidate)


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if(error.response)
  {
    if (error.response.status === 401 && router.history._startLocation!="/verification" && router.history._startLocation!="/login") {
      router.push('/login')
    }
    if (error.response.status > 500) {
      router.push('/erreur-500')
    }
  }
  return Promise.reject(error)
})

// VueFilterDateFormat
// https://vuejsexamples.com/simple-datetime-filter-for-vue-js/
Vue.use(VueFilterDateFormat);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
