// import axios from 'axios'
import axios from 'axios'
import router from '../../../router/index'
const authentification = {
    state:{
        token:null,
        ulid:null,
        pseudo:null,
        isAuthenticated: false,
        roles:'',
        originalImage:'',
        webpImage: '',
        fullname:'',
        tel:'',
        email:''
    },
    mutations:{
        authUser(state,userData){
            state.token= userData.token,
            state.ulid= userData.ulid,
            state.pseudo = userData.pseudo,
            state.roles = userData.roles,
            state.originalImage = userData.originalImage,
            state.webpImage = userData.webpImage,
            state.isAuthenticated = userData.isAuthenticated
        },
        clearAuth(state){
            state.pseudo = null,
            state.ulid = null,
            state.roles = null,
            state.isAuthenticated = false,
            state.token = null,
            state.originalImage = null,
            state.webpImage = null
        },
        SET_MY_INFOS(state,payload){
            state.username = payload.username
            state.pseudo = payload.username
            state.isAuthenticated = true
            // state.fullname = payload.firstname +' '+ payload.lastname
            // state.tel = payload.tel
            // state.email = payload.email
        },
        SET_AUTHENTICATION(state, payload) {
            state.isAuthenticated = payload
        }
    },
    actions:{
        login({commit},Auth){
            const resGet = Auth.resmyinfo
            const res = Auth.reslogin
            let originalImage = ""
            let webpImage = ""

            if (resGet.data.image) {
                originalImage=resGet.data.image.content.originalImage
                webpImage=resGet.data.image.content.webpImage
            }

            commit('authUser', {
                ulid:resGet.data.ulid,
                token: res.data.token,
                pseudo: res.data.pseudo,
                roles: res.data.roles,
                originalImage:originalImage,
                webpImage: webpImage,
                isAuthenticated : true,
            }
            )
             localStorage.setItem('ulid', resGet.data.ulid)
             //Utiliser local storage
             localStorage.setItem('pseudo', res.data.pseudo)
             //date d'expiration de local storage
             localStorage.setItem('expiresIn', res.data.expires)
             localStorage.setItem('originalImage', originalImage)
             localStorage.setItem('webpImage', webpImage)
            // dispatch('getMyinfos')
            //  router.replace('/')

        },
        set_authentication ({commit}, payload ) {
            commit("SET_AUTHENTICATION", payload.isauthenticated)
            commit ("SET_MY_INFOS", payload.user_info)
        },
        getMyinfos(context){
            axios.get('myinfos')
            .then(resGetMyinfos =>{
                context.commit("SET_MY_INFOS", resGetMyinfos.data)
            })
            .catch(()=>{
                console.log("Vous n'êtes pas connectés");
                context.commit("SET_AUTHENTICATION", false)
            })
        },
        logout({commit}){
            localStorage.clear()
            commit('clearAuth')
            router.replace('/')
        },
        //Charger dans localstorage
        // AutoLogin({commit}){ 
        //     const token = localStorage.getItem('token')
        //     const date_dexpiration = localStorage.getItem('expiresIn')
        //     const pseudo =localStorage.getItem('pseudo')
        //     const ulid =localStorage.getItem('ulid')
        //     const originalImage = localStorage.getItem('originalImage')
        //     const webpImage = localStorage.getItem('webpImage')

        //     const now = new Date()
        //     const exp = new Date(date_dexpiration)
        //     console.log(now <= exp)
        //     commit('authUser',{
        //         ulid:ulid,
        //         token:token,
        //         pseudo:pseudo,
        //         isAuthenticated:true,
        //         originalImage: originalImage,
        //         webpImage: webpImage
        //     })
        // },
        //expiration 
        AutoLogout(){

        }
    },
    getters:{

    }
}

export default authentification;